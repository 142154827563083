import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comments"
export default class extends Controller {
  connect() {}

  toggleForm(event) {
    event.preventDefault();
    event.stopPropagation();

    const formId = event.params["form"];
    const commentBodyId = event.params["body"];

    const form = document.getElementById(formId);
    form.classList.toggle("comment-form__hidden");

    const commentBody = document.getElementById(commentBodyId);
    commentBody.classList.toggle("comment-form__hidden");
  }
}

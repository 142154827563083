import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["links", "template", "steps"];

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";

    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      draggable: ".nested-fields",
      handle: ".form--inline--handle",
    });
  }

  end() {
    this.stepsTargets.forEach((stepTarget, index) => {
      stepTarget.querySelector("input[name*='position']").value = index + 1;
    });
  }

  add_association(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );

    this.linksTarget.insertAdjacentHTML("beforebegin", content);

    // if a step existed before this one, copy over the data
    // on vill distribution
    let stepsLen = this.stepsTargets.length;
    if (stepsLen > 1) {
      let lastEntry = this.stepsTargets[stepsLen - 2];
      let newEntry = this.stepsTargets[stepsLen - 1];

      // get old values
      let foodVills = lastEntry.querySelector(
        "input[name*='food_vills']"
      ).value;
      let woodVills = lastEntry.querySelector(
        "input[name*='wood_vills']"
      ).value;
      let goldVills = lastEntry.querySelector(
        "input[name*='gold_vills']"
      ).value;
      let stoneVills = lastEntry.querySelector(
        "input[name*='stone_vills']"
      ).value;

      // set new values
      newEntry.querySelector("input[name*='food_vills']").value = foodVills;
      newEntry.querySelector("input[name*='wood_vills']").value = woodVills;
      newEntry.querySelector("input[name*='gold_vills']").value = goldVills;
      newEntry.querySelector("input[name*='stone_vills']").value = stoneVills;
    }
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest("." + this.wrapperClass);

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
  }
}
